<template>
    <div>

      <div class="flex items-center" v-if="products.length === 1">
        <vs-avatar
          :src="`https://portal-images.rapid.education/?s3=product-pictures/${products[0].image||'default'}.jpeg&w=64&h=64&fit=cover`"
          class="flex-shrink-0 mr-2" size="30px" @click="$router.push(`/a/products/${products[0].id}`)"/>
        <router-link :to="`/a/products/${products[0].id}`" @click.stop.prevent class="text-inherit hover:text-primary">{{
            products[0].name
          }}
        </router-link>
      </div>

      <div v-for="product of products" class="flex items-center" v-else>
        <router-link v-if="product.name" :to="`/a/products/${product.id}`" class="text-inherit hover:text-primary"
                     @click.stop.prevent>
          <vx-tooltip color="primary" :text="product.name">
            <vs-avatar
              :src="`https://portal-images.rapid.education/?s3=product-pictures/${product.image||'default'}.jpeg&w=64&h=64&fit=cover`"
              class="flex-shrink-0 mr-2" size="30px"/>
          </vx-tooltip>
        </router-link>
      </div>

    </div>
</template>

<script>
export default {
  data () {
    return {
      products: []
    }
  },
  created () {

    this.params.data.product_ids.forEach(item => {
      this.$http.get(`billing/products/${item}`, )
        .then(response => {
          this.products.push(response.data.data)
        })
        .catch(exception => {
          let error = 'An unknown error occurred loading product details'
          if (exception.response) {
            error = exception.response.data.error.description
          }
          return this.$vs.notify({
            title: 'Failed to author',
            text: error,
            color: 'danger',
            position: 'top-right'
          })
        })
    })
  }
}

</script>
