<template>
  <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
    {{new Date(this.params.value * 1000) | date(true)}}
  </div>
</template>

<script>
export default {
  name: 'CellRendererActions'
};
</script>
