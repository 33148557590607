<template>
  <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
    {{this.params.value | currency}}
  </div>
</template>

<script>
export default {
  name: 'CellRendererCurrency'
};
</script>
